import { useEffect } from 'react';

const useViewability = (ref, event_tracking, verification_resources) => {
  const [firstDelayedPrintwUrl] = event_tracking?.delayed_print ?? [];
  const [firstViewUrl] = event_tracking?.view ?? [];

  useEffect(() => {
    const delayedPrint = firstDelayedPrintwUrl ? firstDelayedPrintwUrl.url : '';
    const startViewabilitySession = () => {
      if (window.viewability && ref.current && firstViewUrl?.url) {
        const session = window.viewability.addSession(verification_resources, delayedPrint);
        session.setMeliVaTrackUrl(firstViewUrl.url);
        session.start(ref.current);
        window.viewability.setTag(verification_resources, ref.current);
      }
    };

    if (window.viewability) {
      startViewabilitySession();
    }
  // eslint-disable-next-line
  }, [ref, event_tracking, verification_resources]);
};

export default useViewability;
