import React from 'react';
import { arrayOf, oneOf, string, shape } from 'prop-types';
import { Script } from 'nordic/script';

/* istanbul ignore next */
const ScriptTracks = ({ url, event_tracking, verification_resources, nonce, itemClassName, hydrate }) => {
  const [firstDelayedPrintUrl] = event_tracking?.delayed_print ?? [];
  const [firstViewUrl] = event_tracking?.view ?? [];
  const delayedPrint = firstDelayedPrintUrl ? firstDelayedPrintUrl.url : '';

  return (
    <>
      <div id="verification-resources" data-resources={JSON.stringify(verification_resources)} hidden />
      <Script>
        {`var trackMclicks = document.createElement('img');
          trackMclicks.src = '${url}';
          trackMclicks.nonce = '${nonce}';
          trackMclicks.style.opacity='0.0';
          trackMclicks.style.visibility='hidden';
          trackMclicks.style.position='absolute';
          trackMclicks.style.zIndex='-1';
          trackMclicks.classList.add("ads-carousel-pixel");
          document.body.appendChild(trackMclicks);

          if (${hydrate === 'defer'}) {
            var firstViewUrl = ${JSON.stringify(firstViewUrl)};
            var delayedPrint = '${delayedPrint}';
            var dataElement = document.getElementById('verification-resources')
            var verificationResources = JSON.parse(dataElement.dataset.resources);

            var carouselItemRef = document.querySelector('.${itemClassName}');
              
            function startViewabilitySession() {
              if (window.viewability && carouselItemRef && firstViewUrl?.url) {
                var session = window.viewability.addSession(verificationResources, delayedPrint);
                session.setMeliVaTrackUrl(firstViewUrl.url);
                session.start(carouselItemRef);
                window.viewability.setTag(verificationResources, carouselItemRef);
              }
            };

            document.getElementById('viewability-script').addEventListener('load', function() {
              if (window.viewability) {
                startViewabilitySession();
              }
            });
          }
        `}
      </Script>
    </>
  );
};

ScriptTracks.propTypes = {
  url: string,
  event_tracking: shape({
    print: arrayOf(
      shape({
        url: string,
      }),
    ),
    view: arrayOf(
      shape({
        url: string,
      }),
    ),
  }),
  verification_resources: arrayOf(shape({})),
  nonce: string,
  itemClassName: string,
  hydrate: oneOf(['defer', 'now']),
};

export default ScriptTracks;
